<template>
    <b-card>
        <div class="information-container">
            {{ `${this.$t("Management.Accounting.Title")} ${this.$t(`Management.Accounting.Months.${this.month}`)}/${this.year} - ${this.shareholder}` }}
        </div>
    <CertificatesTable
        :rows="availableCertificates"
        @submit="save"
    ></CertificatesTable>
    </b-card>
  </template>

  <script>
  import { BCard, BRow, BCol } from "bootstrap-vue";
  import CertificatesTable from "./components/certificates-table.vue";
  
  export default {
  
      components: {
        BCard,
        CertificatesTable,
        BRow,
        BCol
      },
      props:{
        shareholder: {
            type: String,
            required: true
        },
        month: {
            type: String,
            required: true
        },
        year: {
            type: String,
            required: true
        },
        processType: {
            type: String,
            required: true
        }
      },
      data: () => ({
        availableCertificates: [],
        invoice: {
            shareholder: null,
            month: null,
            year: null,
            certificates: []
        }
      }),
      created(){
        this.load();
      },
      methods:{
        async load(){
            try {
                const obj = {
                    shareholder: this.shareholder,
                    month: this.month,
                    year: this.year,
                    processType: this.processType
                };
                const { data } = await this.$axios.post('certificates/accountingcertificates', obj)
                if(data){
                  this.availableCertificates = data;
                }
            }catch{
                this.$alert.error();
            }

        },
        async save(selectedCertificates) {
          try {
            this.invoice.month = this.month;
            this.invoice.year = this.year;
            this.invoice.shareholder = this.shareholder;
            this.invoice.processType = this.processType;
            selectedCertificates.forEach(cert => {
                this.invoice.certificates.push(cert.id);
            });

            const { data } = await this.$axios.post('invoices', this.invoice);
            this.invoice = data;
            this.$alert.create(`${data.name}`);
            this.$router.push({ name: "accounting" });
          } catch {
            this.$alert.error()
          }
      },
      },
  }
  </script>
  <style lang="scss">
  .information-container{
    font-weight: bold;
    text-align: center;
    font-size: large;
    margin-bottom: 3rem;
  }
</style>